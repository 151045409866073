<template>
  <div class="customContainer">
    <div class="headerWrapper">
      <v-toolbar-title class="mr-5 ml-0">
        <router-link to="/" tag="span" style="cursor: pointer">
          <div class="logoContainer">
            <kimo-logo />
            <!-- <img src="/kimo_transparent.svg" style="min-height: 100%" /> -->
          </div>
        </router-link>
      </v-toolbar-title>
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
      <router-link to="/" tag="span" style="cursor: pointer">
        <div class="kimoText">
          <kimo-text />
        </div>
      </router-link>
      <v-spacer></v-spacer>
      <div v-show="headerLoad">
        <a
          v-if="token === null"
          href="https://auth.bolor.net/login?t=kimo"
          style="
             {
              color: #ffffff;
              text-decoration: none;
            }
          "
        >
          <v-btn class="customButton small"> Нэвтрэх </v-btn>
        </a>
        <v-btn v-else class="customButton small" @click="logOut"> Гарах </v-btn>
      </div>
    </div>
    <!-- <v-snackbar
      :timeout="3000"
      v-model="success"
      class="message"
      absolute
      center
      top
      color="green accent-6"
    >
      <v-icon left>mdi-check-circle-outline</v-icon>
      {{ info.success }}
    </v-snackbar>
    <v-snackbar
      :timeout="3000"
      v-model="error"
      absolute
      class="message"
      center
      top
      color="red accent-6"
    >
      <v-icon left>mdi-alert-circle-outline</v-icon>
      {{ info.error }}
    </v-snackbar>
    <v-snackbar
      :timeout="3000"
      v-model="warning"
      absolute
      center
      class="message"
      top
      color="warning"
    >
      <v-icon left>mdi-alert-octagon-outline</v-icon>
      {{ info.warning }}
    </v-snackbar> -->
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import KimoLogo from "../components/icons/KimoLogo.vue";
import KimoText from "../components/icons/KimoText.vue";
export default {
  name: "Header",
  components: {
    KimoLogo,
    KimoText,
  },
  data: () => ({
    headerLoad: false,
    token: null,
  }),
  computed: {
    ...mapState(["info"]),
    warning: {
      get() {
        return this.$store.state.snackbars.warning;
      },
      set() {
        this.clearWarningMessage();
      },
    },
    error: {
      get() {
        return this.$store.state.snackbars.error;
      },
      set() {
        this.clearErrorMessage();
      },
    },
    success: {
      get() {
        return this.$store.state.snackbars.success;
      },
      set() {
        this.clearSuccessMessage();
      },
    },
    getToken() {
      return this.$cookie.get("token");
    },
  },
  mounted() {
    this.token = this.getToken;
    this.checkToken();
  },
  methods: {
    ...mapActions([
      "clearSuccessMessage",
      "clearErrorMessage",
      "clearWarningMessage",
    ]),

    logOut() {
      axios
        .post(
          process.env.VUE_APP_BASE_URL + "/logout",
          {},
          {
            headers: { token: this.getToken },
          }
        )
        .then(() => {
          this.token = null;
          this.$cookie.delete("token");
        });
    },
    checkToken() {
      if (this.getToken === null) {
        this.headerLoad = true;
        return;
      }
      axios
        .post(
          process.env.VUE_APP_BASE_URL + "/checkToken",
          {},
          {
            headers: { token: this.getToken },
          }
        )
        .then(() => {
          this.headerLoad = true;
        })
        .catch((reason) => {
          this.headerLoad = true;
          console.log(reason.response);
          // this.login();
          if (reason.response.status == 403) {
            this.$cookie.delete("token");

            this.login();
          }
        });
    },
    login() {
      window.location.href = process.env.VUE_APP_BASE_URL + "/login";
    },
  },
};
</script>
<style scoped>
.logoContainer {
  display: flex;
  align-items: center;
}

.logoText {
  color: #3c3c3b;
}

.kimoText {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .headerWrapper {
    position: relative;
  }

  .logoContainer {
    width: 40px;
    height: 40px;
  }
}
.headerWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.kimoLogo {
  width: 48px;
  height: 57px;
}

.countryFlagWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button {
  outline: none !important;
  width: auto !important;
  height: auto !important;
  margin-left: 13px;
}

.product-title {
  padding: 6px;
  display: flex;
  justify-content: center;
}

.product-row {
  display: flex;
}

.product-item {
  width: 122px;
  height: 122px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  color: #000;
}

.product-logo {
  margin: 10px;
}

.product-item > p {
  font-size: 14px;
  margin: 0px;
}

.user-info {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}

.avatar-placeholder {
  margin: 20px;
  background-color: #777ff6;
  padding: 25px;
  border-radius: 50%;
}

.user-logout {
  padding: 12px 19px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 86px;
}

.user-logout:hover {
  background-color: #eceef5;
}

.user-header {
  display: flex;
  align-items: center;
}

.user-header-title {
  color: #767676;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
}

.user-email {
  font-size: 14px;
  color: #767676;
}

.bottom-light {
  width: 100%;
  height: 0px;
  border-radius: 2px;
  background-color: #777ff6;
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  margin: 0px 20px;
}

.help {
  display: flex;
  align-items: center;
  margin: 0px 20px;
}

.login {
  margin-left: 13px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
}

.message {
  margin-top: 50px;
}

.headerMenu {
  display: flex;
}

.profile-info {
  color: #777ff6;
  font-size: 14px;
  cursor: pointer;
}
.message {
  margin-top: 80px;
}

@media screen and (max-width: 599px) {
  .countryFlagWrapper {
    display: none;
  }

  .headerMenu {
    display: none;
  }
}
</style>
