export default {
    showSuccessMessage: ({ commit }, payload) => {
      commit("updateSuccessMessage", { state: true, message: payload });
    },
    clearSuccessMessage: ({ commit }) => {
      commit("updateSuccessMessage", { state: false, message: "" });
    },
    showErrorMessage: ({ commit }, payload) => {
      commit("updateErrorMessage", { state: true, message: payload });
    },
    clearErrorMessage: ({ commit }) => {
      commit("updateErrorMessage", { state: false, message: "" });
    },
    showWarningMessage: ({ commit }, payload) => {
      commit("updateWarningMessage", { state: true, message: payload });
    },
    clearWarningMessage: ({ commit }) => {
      commit("updateWarningMessage", { state: false, message: "" });
    },
  };
  