import Vue from "vue";
import VueRouter from "vue-router";
import start from "../views/start.vue";
import help from "../views/help.vue";
import history from "../views/history.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Кимо хөрвүүлэгч",
    component: start,
  },
  {
    path: "/history",
    name: "history",
    component: history,
  },
  {
    path: "/help",
    name: "help",
    component: help,
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.name;
  if (to.query.t) {
    Vue.cookie.set("token", to.query.t);
    next("/");
  }
  next();
});
export default router;
