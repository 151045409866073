export default {
  updateSuccessMessage: (state, payload) => {
    state["snackbars"].success = payload.state;
    state["info"].success = payload.message;
  },
  updateErrorMessage: (state, payload) => {
    state["snackbars"].error = payload.state;
    state["info"].error = payload.message;
  },
  updateWarningMessage: (state, payload) => {
    state["snackbars"].warning = payload.state;
    state["info"].warning = payload.message;
  },
};
