export default {
    snackbars: {
      success: false,
      error: false,
      warning: false,
    },
    info: {
      success: "",
      error: "",
      warning: "",
    },
  };
  