<template>
  <svg
    :width="width ? width : 13"
    :height="height ? height : 9"
    viewBox="0 0 13 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="triangleIcon"
  >
    <path
      d="M11.9945 0.786743H0.799473C0.463692 0.786743 0.276199 1.14127 0.484145 1.38331L6.08164 7.87394C6.24186 8.05973 6.55037 8.05973 6.7123 7.87394L12.3098 1.38331C12.5177 1.14127 12.3303 0.786743 11.9945 0.786743Z"
    />
  </svg>
</template>
<script>
export default {
  name: "TriangleIcon",
  props: {
    width: Number,
    height: Number,
  },
};
</script>
