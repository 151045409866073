<template>
  <div class="containerCanvas" style="text-align: center">
    <canvas class="canvas" id="mnglCanvas"></canvas>
  </div>
</template>
<script>
export default {
  expose: ["drawCanvas"],
  props: [
    "focusScriptId",
    "canvasText",
    "activeFontId",
    "color",
    "bgColor",
    "fontSize",
    "marginX",
    "marginY",
    "isAlign",
    "hasEmoji",
  ],
  data() {
    return {
      lineHeight: 40,
      scale: 1,
      emoji: /\p{Extended_Pictographic}/u,
    };
  },
  watch: {
    color() {
      this.drawCanvas();
    },
    bgColor() {
      this.drawCanvas();
    },
    fontSize() {
      this.drawCanvas();
    },
    focusScriptId() {
      this.drawCanvas();
    },
    marginX() {
      this.drawCanvas();
    },
    marginY() {
      this.drawCanvas();
    },
    isAlign() {
      this.drawCanvas();
    },
  },
  methods: {
    drawCanvas() {
      let canvas = document.getElementById("mnglCanvas");
      let ctx = canvas.getContext("2d");

      this.lineHeight = this.GetLineHeight(this.fontSize, this.canvasText);
      canvas.height = this.setHeight(this.fontSize, this.canvasText.length);
      if (this.canvasText.split(" ").length == 1) {
        canvas.width = this.setWidth(
          ctx,
          this.fontSize,
          this.activeFontId,
          canvas.height - this.marginY * 2,
          this.canvasText
        );
      } else {
        canvas.width =
          this.setWidth(
            ctx,
            this.fontSize,
            this.activeFontId,
            canvas.height - this.marginY * 2,
            this.canvasText
          ) +
          (this.lineHeight - this.fontSize);
      }

      //Drawing background Rectangle
      ctx.beginPath();
      ctx.rect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = this.bgColor;
      ctx.fill();
      //Filling text
      ctx.fillStyle = this.color;
      if (this.activeFontId == 0) {
        ctx.font = `${this.fontSize}px MongolianScript`;
      } else if (this.activeFontId == 1) {
        ctx.font = `${this.fontSize}px Classical Mongolian Dashtseden`;
      }
      ctx.rotate(Math.PI / 2);
      //Calculating max height of lines of text start
      let arr = (wrappedText = this.wrapText(
        ctx,
        this.canvasText,
        0,
        0,
        canvas.height - this.marginY * 2,
        this.lineHeight
      ));
      let heightLine = [];
      arr.forEach(function (item) {
        let a = ctx.measureText(item[0]).width;
        heightLine.push(a);
      });
      let max = Math.max(...heightLine);

      let wrappedText = "";
      let startPointX = 0;
      let startPointY = 0;
      if (
        this.hasEmoji == true &&
        arr.length == 1 &&
        this.canvasText.split(" ").length == 1
      ) {
        startPointX =
          this.marginY + (canvas.height - this.marginY * 2 - max) / 2;
        startPointY = -canvas.width / 2;
      } else if (
        this.hasEmoji == true &&
        arr.length !== 1 &&
        this.canvasText.split(" ").length == 1
      ) {
        startPointX =
          this.marginY + (canvas.height - this.marginY * 2 - max) / 2;
        startPointY = -(this.lineHeight - this.fontSize) / 6 - this.marginX;
      } else if (
        this.hasEmoji == true &&
        arr.length == 1 &&
        this.canvasText.split(" ").length !== 1
      ) {
        startPointX =
          this.marginY + (canvas.height - this.marginY * 2 - max) / 2;
        startPointY = -(this.lineHeight - this.fontSize) * 2 - this.marginX;
      } else if (
        this.hasEmoji == false &&
        arr.length !== 1 &&
        this.canvasText.split(" ").length == 1
      ) {
        startPointX =
          this.marginY + (canvas.height - this.marginY * 2 - max) / 2;
        startPointY =
          -(this.lineHeight - this.fontSize) +
          (this.lineHeight - this.fontSize) / 2 -
          this.marginX;
      } else if (
        this.hasEmoji == false &&
        arr.length == 1 &&
        this.canvasText.split(" ").length == 1
      ) {
        ctx.textAlign = "center";
        startPointX = this.marginY;
        startPointY = -canvas.width / 2;
      } else if (
        this.hasEmoji == false &&
        arr.length == 1 &&
        this.canvasText.split(" ").length !== 1
      ) {
        startPointX =
          this.marginY + (canvas.height - this.marginY * 2 - max) / 2;
        startPointY = -(this.lineHeight - this.fontSize) - this.marginX;
      } else {
        startPointX =
          this.marginY + (canvas.height - this.marginY * 2 - max) / 2;
        startPointY = -(this.lineHeight - this.fontSize) - this.marginX;
      }

      wrappedText = this.wrapText(
        ctx,
        this.canvasText,
        startPointX,
        startPointY,
        canvas.height - this.marginY * 2,
        this.lineHeight
      );
      // console.log("check", wrappedText);
      //Wrapping each words by its position by all word lines
      wrappedText.forEach((item) => {
        //If there are no emojis in the text
        if (this.hasEmoji == false) {
          if (this.canvasText.split(" ").length == 1 && arr.length == 1) {
            item[1] += (canvas.height - this.marginY * 2) / 2;
            ctx.fillText(item[0], item[1], item[2] + this.fontSize / 4);
          } else if (
            this.isAlign == true &&
            this.canvasText.split(" ").length !== 1
          ) {
            ctx.textAlign = "center";
            ctx.fillText(
              item[0],
              canvas.height / 2,
              item[2] - this.fontSize / 4
            );
          } else {
            ctx.fillText(item[0], item[1], item[2] - this.fontSize / 4);
          }
        }
        //If there have emojis in the text
        else {
          let rowText = item[0].split("\n");
          if (this.isAlign === true) {
            item[1] += (max - ctx.measureText(item[0]).width) / 2;
          }
          for (var i = 0; i < rowText.length; i++) {
            let word = rowText[i].split(" ");
            for (var n = 0; n < word.length; n++) {
              const splitEmoji = (string) =>
                [...new Intl.Segmenter().segment(string)].map((x) => x.segment);
              if (this.emoji.test(word[n])) {
                //Slpitting word letter by letter
                var emojiArr = splitEmoji(word[n]);
                //If there aer one emoji then rotate it
                if (emojiArr.length == 1) {
                  ctx.save();
                  ctx.translate(
                    ctx.measureText(word[n]).width / 2,
                    ctx.measureText(word[n]).width / 2
                  );
                  ctx.rotate(-Math.PI / 2);
                  ctx.fillText(
                    word[n],
                    -item[2] + ctx.measureText(word[n]).width / 4,
                    item[1] + ctx.measureText(word[n]).width / 4
                  );
                  ctx.restore();
                } else {
                  var y = item[1];
                  var y1 = item[1];
                  var emojiHeight = 0;
                  var notEmojies = "";
                  var textOfEmoji = "";
                  var widthEmojiWithWord = 0;
                  for (var idx = 0; idx < emojiArr.length; idx++) {
                    if (this.emoji.test(emojiArr[idx])) {
                      if (notEmojies != "") {
                        if (this.canvasText.split(" ").length == 1) {
                          ctx.fillText(
                            notEmojies,
                            item[1] + emojiHeight + widthEmojiWithWord,
                            item[2] + ctx.measureText(emojiArr[idx]).width / 4
                          );
                        } else {
                          ctx.fillText(
                            notEmojies,
                            item[1] + emojiHeight + widthEmojiWithWord,
                            item[2]
                          );
                        }
                        textOfEmoji = notEmojies;

                        ctx.save();
                        ctx.translate(
                          ctx.measureText(emojiArr[idx]).width / 2,
                          ctx.measureText(emojiArr[idx]).width / 2
                        );
                        ctx.rotate(-Math.PI / 2);

                        if (this.canvasText.split(" ").length == 1) {
                          ctx.fillText(
                            emojiArr[idx],
                            -item[2] +
                              ctx.measureText(emojiArr[idx]).width / 24,
                            y1 +
                              ctx.measureText(notEmojies).width +
                              emojiHeight +
                              widthEmojiWithWord +
                              10
                          );
                        } else {
                          ctx.fillText(
                            emojiArr[idx],
                            -item[2] + ctx.measureText(emojiArr[idx]).width / 4,
                            item[1] +
                              ctx.measureText(notEmojies).width +
                              emojiHeight +
                              widthEmojiWithWord +
                              10
                          );
                        }
                        ctx.restore();
                        widthEmojiWithWord += ctx.measureText(
                          notEmojies + emojiArr[idx]
                        ).width;
                        notEmojies = "";
                      } else if (textOfEmoji != "") {
                        ctx.save();
                        ctx.translate(
                          ctx.measureText(emojiArr[idx]).width / 2,
                          ctx.measureText(emojiArr[idx]).width / 2
                        );
                        ctx.rotate(-Math.PI / 2);
                        if (this.canvasText.split(" ").length == 1) {
                          ctx.fillText(
                            emojiArr[idx],
                            -item[2] +
                              ctx.measureText(emojiArr[idx]).width / 24,
                            y + 10 + emojiHeight + widthEmojiWithWord
                          );
                        } else {
                          ctx.fillText(
                            emojiArr[idx],
                            -item[2] + ctx.measureText(emojiArr[idx]).width / 4,
                            y + 10 + emojiHeight + widthEmojiWithWord
                          );
                        }
                        ctx.restore();

                        emojiHeight += ctx.measureText(emojiArr[idx]).width;
                      } else {
                        ctx.save();
                        ctx.translate(
                          ctx.measureText(emojiArr[idx]).width / 2,
                          ctx.measureText(emojiArr[idx]).width / 2
                        );
                        ctx.rotate(-Math.PI / 2);
                        if (this.canvasText.split(" ").length == 1) {
                          ctx.fillText(
                            emojiArr[idx],
                            -item[2] +
                              ctx.measureText(emojiArr[idx]).width / 24,
                            y + ctx.measureText(emojiArr[idx]).width / 4
                          );
                        } else {
                          ctx.fillText(
                            emojiArr[idx],
                            -item[2] + ctx.measureText(emojiArr[idx]).width / 4,
                            y + ctx.measureText(emojiArr[idx]).width / 4
                          );
                        }
                        ctx.restore();
                        emojiHeight += ctx.measureText(emojiArr[idx]).width;
                      }
                    } else {
                      notEmojies += emojiArr[idx];
                    }
                    if (textOfEmoji != "") {
                      y = item[1];
                    } else {
                      y += ctx.measureText(emojiArr[idx]).width;
                    }
                  }
                  if (notEmojies != "") {
                    if (this.canvasText.split(" ").length == 1) {
                      ctx.fillText(
                        notEmojies,
                        item[1] + emojiHeight + widthEmojiWithWord,
                        item[2] + ctx.measureText(emojiArr[idx]).width / 20
                      );
                    } else {
                      ctx.fillText(
                        notEmojies,
                        item[1] + emojiHeight + widthEmojiWithWord,
                        item[2]
                      );
                    }
                    notEmojies = "";
                  }
                }
              } else {
                ctx.fillText(word[n], item[1], item[2]);
              }
              item[1] += ctx.measureText(word[n]).width + 15;
            }
          }
        }
      });

      canvas.style.transform = `scale(${this.scale})`;
    },
    breakText(ctx, word, maxHeight) {
      if (ctx.measureText(word).width < maxHeight) {
        return [word];
      }
      const splitEmoji = (string) =>
        [...new Intl.Segmenter().segment(string)].map((x) => x.segment);
      let breakTextArr = splitEmoji(word);
      let wrd = "";
      let breakArr = [];
      for (var id = 0; id < breakTextArr.length; id++) {
        wrd += breakTextArr[id];
        if (ctx.measureText(wrd).width > maxHeight) {
          breakArr.push(wrd);
          wrd = "";
        }
        if (id === breakTextArr.length - 1) {
          breakArr.push(wrd);
        }
      }
      return breakArr;
    },
    wrapText(ctx, text, x, y, maxHeight, lineHeight) {
      let rowText = text.split("\n");
      let lineArray = [];
      for (var i = 0; i < rowText.length; i++) {
        let word = rowText[i].split(" ");
        let line = "";
        let testLine = "";
        let breakWords = [];
        for (let i = 0; i < word.length; i++) {
          breakWords = this.breakText(ctx, word[i], maxHeight);
          if (breakWords.length !== 1) {
            for (let idx = 0; idx < breakWords.length; idx++) {
              testLine += `${breakWords[idx]} `;
              let metrics = ctx.measureText(testLine);
              let testWidth = metrics.width;
              if (testWidth > maxHeight && line != "") {
                lineArray.push([line, x, y]);
                y -= lineHeight;
                line = `${breakWords[idx]} `;
                testLine = `${breakWords[idx]} `;
              } else {
                line += `${breakWords[idx]} `;
              }
            }
          } else {
            testLine += `${word[i]} `;
            let metrics = ctx.measureText(testLine);
            let testWidth = metrics.width;
            if (testWidth > maxHeight) {
              lineArray.push([line, x, y]);
              y -= lineHeight;
              line = `${word[i]} `;
              testLine = `${word[i]} `;
            } else {
              line += `${word[i]} `;
            }
          }
          if (i === word.length - 1) {
            lineArray.push([line, x, y]);
          }
        }
        y -= lineHeight;
      }
      return lineArray;
    },
    setWidth(ctx, font, activeFontId, height, text) {
      if (activeFontId == 0) {
        ctx.font = `${font}px MongolianScript`;
      } else if (activeFontId == 1) {
        ctx.font = `${font}px Classical Mongolian Dashtseden`;
      }
      ctx.rotate(Math.PI / 2);
      let array = this.wrapText(ctx, text, 0, 0, height, this.lineHeight);
      return array.length * this.lineHeight + this.marginX * 2;
    },
    setHeight(font, length) {
      var font1 = 16;
      var difference = font / font1;
      if (length > 0 && length <= 30) {
        if (difference >= 3) {
          return 1200 + this.marginY * 2;
        } else return 600 + this.marginY * 2;
      } else if (length > 30 && length <= 100) {
        if (difference >= 4) {
          return 1200 + this.marginY * 2;
        } else if (difference < 4 && difference >= 2.25) {
          return 900 + this.marginY * 2;
        } else return 500 + difference + this.marginY * 2;
      } else if (length > 100 && length <= 500) {
        if (difference >= 3) {
          return 1400 + this.marginY * 2;
        } else return 700 + this.marginY * 2;
      } else if (length <= 1000) {
        if (difference >= 3) {
          return 1800 + this.marginY * 2;
        } else return 1000 + difference + this.marginY * 2;
      } else if (length > 1000 && length <= 1500) {
        if (difference >= 3) {
          return 2000 + this.marginY * 2;
        } else return 1000 + this.marginY * 2;
      } else if (length > 1500) {
        if (difference >= 3.5) {
          return 2000 + this.marginY * 2;
        } else return 1200 + this.marginY * 2;
      }
    },
    GetLineHeight(font, text) {
      var height = 0;
      if (font >= 16 && font < 28) {
        height = 40;
      } else if (font >= 28 && font < 36) {
        height = 56;
      } else if (font >= 36 && font < 40) {
        if (text.length > 0 && text.length <= 30) {
          height = 48;
        } else {
          height = 62;
        }
      } else if (font >= 40 && font < 48) {
        if (text.length > 0 && text.length <= 30) {
          height = 54;
        } else {
          height = 74;
        }
      } else if (font >= 48 && font < 56) {
        if (text.length > 0 && text.length <= 30) {
          height = 64;
        } else {
          height = 86;
        }
      } else if (font >= 56 && font < 64) {
        if (text.length > 0 && text.length <= 30) {
          height = 72;
        } else {
          height = 96;
        }
      } else if (font >= 64 && font < 72) {
        if (text.length > 0 && text.length <= 30) {
          height = 82;
        } else {
          height = 112;
        }
      } else if (font >= 72 && font < 80) {
        if (text.length > 0 && text.length <= 30) {
          height = 92;
        } else {
          height = 126;
        }
      } else if (font >= 80) {
        if (text.length > 0 && text.length <= 30) {
          height = 102;
        } else {
          height = 138;
        }
      }
      return height;
    },
  },
};
</script>

<style scoped>
#mnglCanvas {
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 90%;
  max-height: 90%;
}
.containerCanvas {
  width: 100%;
  height: 100%;
  position: relative;
}
.canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
</style>