<template>
  <div class="customContainer">
    <div class="footerContainer">
      <div class="footerTitle">
        2022 © Тунгаамал загварыг ашиглан ‘’Болорсофт’’ ХХК хөгжүүлэв.
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.footerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(28, 35, 30, 0.1);
  padding: 16px 0;
}

.footerTitle {
  font-size: 12px;
  line-height: 14px;
  color: #4a4a4a;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .socialButton {
    width: 32px !important;
    height: 32px !important;
    margin-right: 8px;
  }
}

@media screen and (max-width: 600px) {
  .footerContainer {
    flex-direction: column;
    padding-top: 14px;
  }

  .socialContainer {
    margin-top: 15px;
  }
}
</style>
