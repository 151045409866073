import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookie from "vue-cookie";
import './assets/css/main.css';
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import Clipboard from 'v-clipboard'

import store from "./store";
const options = {
  // You can set your default options here
};



Vue.use(Toast, options);
Vue.use(VueAxios, axios);
Vue.use(VueCookie);
Vue.use(Clipboard);


Vue.use(store);
Vue.config.productionTip = false
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')


