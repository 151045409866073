<template>
  <div class="kimoTitleContainer">
    <div class="customContainer">
      <div class="baseTitleContainer">
        <div class="d-flex flex-column">
          <span class="firstTitle">Монголын анхны</span>
          <span class="secondTitle">Монгол бичиг хөрвүүлэгч</span>
        </div>
        <div class="allLogoContainer">
          <span class="thirdTitle">Хамтран ажиллагч:</span>
          <div class="logos">
            <a
              :key="index"
              class="logoContainer"
              v-for="(logo, index) in logos"
              :href="logo.url"
              target="_blank"
            >
              <v-img
                :src="logo.src"
                class="logoWrapper"
                max-height="82"
                aspect-ratio="1.4"
                contain
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logos: [
        {
          url: "https://bolorsoft.com",
          src: "bolorsoft.png",
        },
        {
          url: "http://www.smart.gov.mn/mn",
          src: "smart.png",
        },
        {
          url: "https://www.innovathon.mn",
          src: "innovaton.png",
        },
        {
          url: "https://www.worldbank.org/en/home",
          src: "world_bank.png",
        },
      ],
    };
  },
};
</script>
<style scoped>
.kimoTitleContainer {
  width: 100%;
  background: linear-gradient(
    174.7deg,
    rgba(207, 207, 207, 0) 24.82%,
    rgba(169, 169, 169, 0.28) 190.7%
  );
  height: 30vh;
  padding-top: 30px;
}

.baseTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.allLogoContainer {
  display: flex;
  align-items: center;
}

.logos {
  display: flex;
}

.firstTitle {
  font-size: 21px;
  color: #000000;
  height: 21px;
}

.secondTitle {
  font-size: 21px;
  font-weight: 700;
  color: #33a953;
  height: 21px;
}

.thirdTitle {
  font-size: 18px;
  font-weight: 500;
  color: #747474;
  margin-right: 36px;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin-right: 24px;
}

.logoContainer:last-child {
  margin-right: 0px;
}

.logoWrapper {
  filter: grayscale(1);
}

.logoWrapper:hover {
  filter: grayscale(0);
  transform: scale(1.2);
  transition: 0.3s ease-in-out;
}

@media screen and (min-width: 960px) and (max-width: 1080px) {
  .firstTitle,
  .secondTitle {
    font-size: 24px;
    height: 24px;
  }

  .thirdTitle {
    font-size: 14px;
    margin-right: 16px;
  }
}

@media screen and (max-width: 960px) {
  .firstTitle,
  .secondTitle {
    font-size: 18px;
    height: 18px;
  }

  .thirdTitle {
    font-size: 12px;
    margin-right: 8px;
  }

  .logoContainer {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }
}

@media screen and (max-width: 768px) {
  .kimoTitleContainer {
    background: none;
    background-color: white;
    height: auto;
    padding-bottom: 16px;
  }

  .baseTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .allLogoContainer {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;
    width: 100%;
  }

  .logoContainer {
    width: 25%;
    margin: 16px 0 24px 0;
    height: 48px;
    margin-right: 0;
    padding-right: 16px;
  }

  .logoContainer:last-child {
    padding-right: 0;
  }

  .logos {
    width: 100%;
    margin-top: 24px;
  }

  .logoWrapper {
    filter: grayscale(0);
  }
}
</style>
