<template>
  <div class="converterFullContaner">
    <div class="converterContainer">
      <div class="converterHeader">
        <div
          class="converterLangTitle"
          v-for="(script, index) in scripts"
          :key="index + renderCount"
        >
          {{ script.title }}
        </div>
        <div class="switchButtonContainer">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="iconButton"
                @click="switchScripts"
                v-bind="attrs"
                v-on="on"
                ><switch-icon
              /></v-btn>
            </template>
            <span>Бичиг солих</span>
          </v-tooltip>
        </div>
        <font-dropdown
          class="fontDropdownContainer"
          :class="focusScriptId == 1 ? 'right' : 'left'"
          :activeFontId.sync="activeFontId"
          v-on:updateFont="updateFont($event)"
        />
      </div>
      <div class="inputContainer">
        <div class="converterLeftContainer">
          <textarea
            v-if="focusScriptId === 1"
            :disabled="convertLoading"
            class="textareaContainer"
            placeholder="Энд дарж бичнэ үү"
            v-model="text"
          ></textarea>
          <div
            v-else
            ref="scriptId"
            class="mongolianScriptContainer mb"
            @input="changeInput"
            @paste="onMngPaste"
            contenteditable
            placeholder="ᠡᠨᠳᠡ ᠮᠣᠩᠭᠣᠯ ᠪᠢᠴᠢᢉ ᠢᠶᠡᠷ ᠪᠢᠴᠢᠨ ᠡ ᠦᠦ !"
            :class="
              activeFontId == 0
                ? 'mongolianScriptFontStyle'
                : 'dashitsedenFontStyle'
            "
          ></div>
          <span
            class="textLimitContainer"
            :class="limitTextCount < text.length ? 'invalidLength' : ''"
            >{{ text.length }}/{{ limitTextCount }}</span
          >
          <div class="mobileTranslateBtnContainer">
            <button
              v-ripple
              class="customButton"
              @click="translate()"
              :loading="convertLoading"
            >
              Хөрвүүлэх
            </button>
          </div>
        </div>
        <div class="converterRightContainer">
          <div v-if="convertLoading" class="convertLoading">
            Хөрвүүлж байна...
          </div>
          <div
            v-else-if="focusScriptId == 2"
            class="mongolianCyrillicContainer"
          >
            {{ translateText }}
          </div>
          <div
            v-else-if="focusScriptId == 1"
            class="mongolianScriptContainer mb"
            :class="
              activeFontId == 0
                ? 'mongolianScriptFontStyle'
                : 'dashitsedenFontStyle'
            "
            ref="mnglImg"
          >
            {{ translateText }}
          </div>
          <div
            class="iconButtonContainer"
            v-if="!convertLoading && translateText !== ''"
          >
            <v-spacer></v-spacer>
            <button
              class="reportBtn mobile mr-2"
              @click="reportMenu = true"
              @mouseenter="reportHovered = true"
              @mouseleave="reportHovered = false"
              v-if="!convertLoading && translateText !== ''"
            >
              <info-icon class="mr-2" :hovered="reportHovered" />
              Алдаа мэдээлэх
            </button>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="infoIconBtn"
                  @click="copyText"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <copy-icon />
                </v-btn>
              </template>
              <span>Бичвэр хуулах</span>
            </v-tooltip>
            <v-tooltip bottom v-if="focusScriptId === 1">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="infoIconBtn"
                  @click="showCanvas"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Зураг болгон татах</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <v-dialog width="400" v-model="reportMenu">
        <div class="white" style="border-radius: 8px; padding: 20px">
          <div
            style="
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              color: #7c7c7c;
            "
          >
            <span style="font-weight: 600; color: #6e7191">
              Алдааны тайлбар
            </span>
            <span style="font-weight: 400">(заавал бичих шаардлагагүй)</span>
          </div>
          <div class="mt-2">
            <v-textarea
              dense
              no-resize
              auto-grow
              placeholder="Хөрвүүлэлт алдаатай байна."
              autofocus
              hide-details
              outlined
              class="reportTextarea"
              style="
                width: 100%;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
              "
              rows="5"
              color="secondary"
              spellcheck="false"
              v-model="reportText"
              :readonly="reportLoading"
            ></v-textarea>
            <div
              style="
                line-height: 100%;
                margin-top: 18px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #7c7c7c;
              "
            >
              Таны санал хүсэлт бүтээгдэхүүнийг сайжруулахад туслах болно.
            </div>
          </div>
          <div class="mt-6 d-flex">
            <v-spacer />
            <v-btn
              height="32"
              color="secondary"
              text
              @click="reportMenu = false"
              style="
                border-radius: 4px;
                padding: 8px 24px !important;
                text-transform: none !important;
              "
              class="mr-1"
            >
              Болих
            </v-btn>
            <v-btn
              color="#6E7191"
              dark
              height="32"
              :loading="reportLoading"
              style="
                background: #6e7191;
                box-shadow: 2px 4px 8px rgba(47, 47, 48, 0.1);
                border-radius: 4px;
                padding: 8px 24px !important;
                text-transform: none !important;
              "
              @click="sendReport"
            >
              Мэдээлэх
            </v-btn>
          </div>
        </div>
      </v-dialog>
      <!-- CANVAS START -->
      <v-dialog
        :width="$vuetify.breakpoint.xsOnly ? '80%' : '70%'"
        scrollable
        v-model="showCanvasDialog"
      >
        <v-card>
          <v-card-title class="justify-center pa-2">
            <span
              :class="
                $vuetify.breakpoint.xsOnly
                  ? 'canvasDialogTitle pa-0'
                  : 'text-h5 canvasDialogTitle'
              "
              >Зураг болгон татан авах</span
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text
            id="containerCanvas"
            class="justify-center grey lighten-2 pa-0"
          >
            <div
              class="mongolianScriptContainerCanvas mb"
              style="overflow: hidden"
            >
              <Canvas
                ref="canvasRef"
                :activeFontId="activeFontId"
                :canvasText="canvasText"
                :focusScriptId="focusScriptId"
                :color="canvasTextColor"
                :bgColor="canvasBgColor"
                :fontSize="canvasFont"
                :marginX="marginX"
                :marginY="marginY"
                :isAlign="isAlign"
                :hasEmoji="hasEmoji"
              ></Canvas>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-col class="d-flex flex-column">
              <v-row
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? 'd-flex flex-column pa-0'
                    : 'd-flex flex-row'
                "
              >
                <v-col
                  class="
                    d-flex
                    flex-row
                    align-center align-self-center
                    pa-1
                    alignCenter
                  "
                >
                  <!-- <v-btn outlined @click="alignCenter()"
                    >Бичвэр голлуулах</v-btn
                  > -->
                  <label
                    v-if="$vuetify.breakpoint.xsOnly"
                    style="font-size: 14px"
                    class="mr-2 my-1"
                    for="isAlign"
                  >
                    Бичвэрийг голлуулах:
                  </label>
                  <label
                    v-if="
                      $vuetify.breakpoint.smAndUp &&
                      $vuetify.breakpoint.mdAndDown
                    "
                    style="font-size: 16px"
                    class="mr-2 my-1"
                    for="isAlign"
                  >
                    Бичвэрийг голлуулах:
                  </label>
                  <label
                    v-if="$vuetify.breakpoint.lgAndUp"
                    style="font-size: 20px"
                    class="mr-2 my-1"
                    for="isAlign"
                  >
                    Бичвэрийг голлуулах:
                  </label>
                  <v-checkbox v-model="isAlign" id="isAlign"></v-checkbox>
                </v-col>
                <v-col class="d-flex flex-row align-center pa-1">
                  <p
                    v-if="$vuetify.breakpoint.xsOnly"
                    style="font-size: 14px"
                    class="mr-2 my-1"
                  >
                    Бичвэрийн өнгө:
                  </p>
                  <p
                    v-if="
                      $vuetify.breakpoint.smAndUp &&
                      $vuetify.breakpoint.mdAndDown
                    "
                    style="font-size: 16px"
                    class="mr-2 my-1"
                  >
                    Бичвэрийн өнгө:
                  </p>
                  <p
                    v-if="$vuetify.breakpoint.lgAndUp"
                    style="font-size: 20px"
                    class="mr-2 my-1"
                  >
                    Бичвэрийн өнгө:
                  </p>
                  <v-menu
                    class="self-center"
                    v-model="colorMenu"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyle" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                          @input="mnglColorChanger"
                          v-model="textColor"
                          flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col class="d-flex flex-row align-center pa-1">
                  <!-- <p class="mr-2 my-2">Арын өнгө оруулах:</p> -->
                  <p
                    v-if="$vuetify.breakpoint.xsOnly"
                    style="font-size: 14px"
                    class="mr-2 my-1"
                  >
                    Арын өнгө оруулах:
                  </p>
                  <p
                    v-if="
                      $vuetify.breakpoint.smAndUp &&
                      $vuetify.breakpoint.mdAndDown
                    "
                    style="font-size: 16px"
                    class="mr-2 my-1"
                  >
                    Арын өнгө оруулах:
                  </p>
                  <p
                    v-if="$vuetify.breakpoint.lgAndUp"
                    style="font-size: 20px"
                    class="mr-2 my-1"
                  >
                    Арын өнгө оруулах:
                  </p>
                  <v-menu
                    class="self-center"
                    v-model="bgColorMenu"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchBgStyle" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                          @input="CanvasBgColorChange"
                          v-model="bgColor"
                          flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col class="d-flex flex-row align-center pa-1">
                  <!-- <p class="mr-2 my-2">Фонтын хэмжээ</p> -->
                  <p
                    v-if="$vuetify.breakpoint.xsOnly"
                    style="font-size: 14px"
                    class="mr-2 my-1"
                  >
                    Фонтын хэмжээ
                  </p>
                  <p
                    v-if="
                      $vuetify.breakpoint.smAndUp &&
                      $vuetify.breakpoint.mdAndDown
                    "
                    style="font-size: 16px"
                    class="mr-2 my-1"
                  >
                    Фонтын хэмжээ
                  </p>
                  <p
                    v-if="$vuetify.breakpoint.lgAndUp"
                    style="font-size: 20px"
                    class="mr-2 my-1"
                  >
                    Фонтын хэмжээ
                  </p>
                  <v-col class="pa-0 ma-0" sm="5">
                    <v-select
                      outlined
                      v-model="selectedFontSize"
                      :items="fontSizes"
                      @change="ChangeCanvasTextFont()"
                      single-line
                      hide-details
                      dense
                    ></v-select>
                  </v-col>
                </v-col>
              </v-row>
              <v-row
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? 'd-flex flex-column pa-0'
                    : 'd-flex flex-row pt-4'
                "
              >
                <v-col
                  :class="
                    $vuetify.breakpoint.xsOnly
                      ? 'd-flex flex-column w-full pa-0'
                      : 'd-flex flex-row pa-0 align-center pa-1'
                  "
                >
                  <p v-if="$vuetify.breakpoint.xsOnly" style="font-size: 14px">
                    Зайг өөрчлөх (хэвтээ)
                  </p>
                  <p
                    v-if="
                      $vuetify.breakpoint.smAndUp &&
                      $vuetify.breakpoint.mdAndDown
                    "
                    style="font-size: 16px"
                    class="mr-2 my-1"
                  >
                    Зайг өөрчлөх (хэвтээ)
                  </p>
                  <p
                    v-if="$vuetify.breakpoint.lgAndUp"
                    style="font-size: 20px"
                    class="mr-2 my-1"
                  >
                    Зайг өөрчлөх (хэвтээ)
                  </p>
                  <v-slider
                    :class="$vuetify.breakpoint.xsOnly ? 'ma-0' : 'mx-3'"
                    v-model="xMargin"
                    :max="max"
                    :min="min"
                    @change="XMarginChange()"
                    :thumb-size="20"
                    thumb-label="always"
                    step="1"
                    hide-details
                  >
                    <!-- tick-size="4" -->
                    <!-- :tick-labels="tickLabelsX" -->
                    <!-- ticks="always" -->
                  </v-slider></v-col
                >

                <v-col
                  :class="
                    $vuetify.breakpoint.xsOnly
                      ? 'w-full d-flex flex-column w-full pa-0'
                      : 'd-flex flex-row align-center pa-1'
                  "
                >
                  <p
                    v-if="$vuetify.breakpoint.xsOnly"
                    style="font-size: 14px"
                    class="mr-2 my-1"
                  >
                    Зайг өөрчлөх (босоо)
                  </p>
                  <p
                    v-if="
                      $vuetify.breakpoint.smAndUp &&
                      $vuetify.breakpoint.mdAndDown
                    "
                    style="font-size: 16px"
                    class="mr-2 my-1"
                  >
                    Зайг өөрчлөх (босоо)
                  </p>
                  <p
                    v-if="$vuetify.breakpoint.lgAndUp"
                    style="font-size: 20px"
                    class="mr-2 my-1"
                  >
                    Зайг өөрчлөх (босоо)
                  </p>
                  <v-slider
                    :class="$vuetify.breakpoint.xsOnly ? 'ma-0' : 'mx-3'"
                    v-model="yMargin"
                    :max="maxY"
                    :min="ymin"
                    @change="YMarginChange()"
                    :thumb-size="20"
                    thumb-label="always"
                    step="1"
                    hide-details
                  >
                    <!-- :tick-labels="tickLabelsX"
                    ticks="always"
                    tick-size="4" -->
                  </v-slider></v-col
                >
              </v-row>
            </v-col>
          </v-card-actions>

          <v-card-actions
            :class="$vuetify.breakpoint.xsOnly ? 'pa-0 mb-3' : 'mt-4 mb-8'"
            class="d-flex justify-center"
          >
            <v-btn
              height="32"
              color="secondary"
              text
              plain
              @click="clearCanvas()"
              style="
                border-radius: 4px;
                padding: 8px 24px !important;
                text-transform: none !important;
              "
              class="mr-1"
            >
              <v-icon left>mdi-chevron-left</v-icon>Болих
            </v-btn>
            <button class="customButton mr-1" @click="downloadText" v-ripple>
              Татах
            </button>
            <!-- <v-btn
              class="ml-4 mr-2"
              color="primary"
              icon
              dark
              @click="postCanvasToFacebook()"
            >
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <v-btn class="mr-2" color="primary" icon dark>
              <v-icon>mdi-instagram</v-icon>
            </v-btn>
            <v-btn class="mr-2" color="primary" icon dark>
              <v-icon>mdi-twitter</v-icon>
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- CANVAS END -->
      <div class="converterFooter">
        <div class="translateButtonContainer">
          <button
            class="customButton"
            @click="translate()"
            :loading="convertLoading"
            v-ripple
          >
            Хөрвүүлэх
          </button>
        </div>
        <v-spacer> </v-spacer>
        <button
          class="reportBtn"
          @click="reportMenu = true"
          @mouseenter="reportHovered = true"
          @mouseleave="reportHovered = false"
          v-if="!convertLoading && translateText !== ''"
        >
          <info-icon class="mr-2" :hovered="reportHovered" />
          Алдаа мэдээлэх
        </button>
      </div>
    </div>
    <div class="helperContainer">
      <div class="questionContainer">
        <button class="descriptionButton" @click="isExpanded = !isExpanded">
          <span class="mr-6">Кимо гэж юу вэ?</span>
          <triangle-icon class="triangleIcon" />
        </button>
        <div v-if="isExpanded" class="moreDesc">
          Хиймэл оюун ухааны тусламжтай кирилл бичгээс монгол бичигт, монгол
          бичгээс кирилл бичигт хөрвүүлдэг хөрвүүлэгч юм.
        </div>
        <div v-if="isExpanded" class="moreDesc">
          Хөрвүүлэх чиглэлээ сонгоод бичвэрээ оруулж хөрвүүлэх товчийг дарна
        </div>
      </div>
      <div class="fontDownloadContainer">
        Та монгол бичигт хувиргасан текстээ алдаагүй ашиглахын тулд манай монгол
        бичгийн фонтыг
        <a
          class="downloadLink"
          href="https://mngl.net/#download"
          target="_blank"
        >
          татаж</a
        >
        суулгана уу.
      </div>
    </div>
    <v-overlay v-model="overlayed">
      <div class="d-flex flex-column justify-center text-center">
        <v-progress-circular
          :size="100"
          :width="8"
          :value="timerValue"
          color="white"
          v-if="timer"
        >
          {{ timerValue / 10 }}
        </v-progress-circular>
        <v-progress-circular
          :size="100"
          :width="8"
          indeterminate
          color="white"
          v-if="transcribeLoading"
        >
        </v-progress-circular>
        {{ overlayMsg }}
      </div>
    </v-overlay>
  </div>
</template>
<script>
import axios from "axios";
import SwitchIcon from "./icons/SwitchIcon.vue";
import TriangleIcon from "./icons/TriangleIcon.vue";
import CopyIcon from "./icons/CopyIcon.vue";
import InfoIcon from "./icons/InfoIcon.vue";
import FontDropdown from "./FontDropdown.vue";
import Canvas from "./Canvas.vue";
import { mapActions } from "vuex";

export default {
  components: {
    SwitchIcon,
    CopyIcon,
    TriangleIcon,
    InfoIcon,
    FontDropdown,
    Canvas,
  },
  data() {
    return {
      scripts: [
        {
          title: "Кирилл",
          id: 1,
        },
        {
          title: "Монгол бичиг",
          id: 2,
        },
      ],
      showCanvasDialog: false,
      reportMenu: false,
      reportLoading: false,
      reportText: "",
      reportHovered: false,
      focusScriptId: 1,
      renderCount: 0,
      text: "",
      translateText: "",
      translateId: null,
      activeFontId: 0, // 0 - mongolianScript, 1 - dashtseden
      limitTextCount: 2000,
      convertLoading: false,
      isExpanded: false,
      isRecording: false,
      overlayed: false,
      overlayMsg: "",
      audioContext: null,
      chunks: [],
      timerValue: 10,
      timer: null,
      transcribeLoading: false,
      textColor: "#000000",
      bgColor: "#ffffff",
      canvasTextColor: "#000000",
      canvasBgColor: "#ffffff",
      canvasFont: "44",
      colorMenu: false,
      bgColorMenu: false,
      fontSizes: [
        "16",
        "20",
        "24",
        "28",
        "32",
        "36",
        "40",
        "44",
        "48",
        "52",
        "56",
        "60",
        "64",
        "68",
        "72",
        "76",
        "80",
        "84",
      ],
      selectedFontSize: "44",
      xMargin: 140,
      yMargin: 140,
      marginX: 140,
      marginY: 140,
      max: 400,
      maxY: 600,
      min: 40,
      ymin: -200,
      canvasText: this.translateText,
      // tickLabelsX: [40, 140, 240],
      url: "",
      isAlign: false,
      emoji: /\p{Extended_Pictographic}/u,
      // emoji: /\p{Emoji}/u,
      hasEmoji: false,
    };
  },
  watch: {
    reportMenu: function (v) {
      if (v) {
        this.reportText = "";
      }
    },
    // showCanvasDialog: function (v) {
    //   if (v) {
    //     // this.translateText = "";
    //     if (this.showCanvasDialog != v) this.showCanvasDialog = v;
    //   }
    // },
  },
  computed: {
    ...mapActions([
      "showWarningMessage",
      "showSuccessMessage",
      "showErrorMessage",
    ]),
    getToken() {
      return this.$cookie.get("token");
    },
    swatchStyle() {
      const { textColor, colorMenu } = this;
      return {
        backgroundColor: textColor,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: colorMenu ? "50%" : "2px",
        transition: "border-radius 200ms ease-in-out",
        border: "1px black solid",
      };
    },
    swatchBgStyle() {
      const { bgColor, bgColorMenu } = this;
      return {
        backgroundColor: bgColor,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: bgColorMenu ? "50%" : "2px",
        transition: "border-radius 200ms ease-in-out",
        border: "1px black solid",
      };
    },
  },
  mounted() {
    const id = this.$cookie.get("activeFontId");
    const text = localStorage.getItem("editText");
    const focusScriptId = this.$cookie.get("focusScriptId");
    if (id != null) {
      this.activeFontId = Number(id);
    }
    if (focusScriptId != null) {
      this.focusScriptId = Number(focusScriptId);
      if (this.focusScriptId === 2) {
        const tempScript = this.scripts[0];
        this.scripts[0] = this.scripts[1];
        this.scripts[1] = tempScript;
      }
    }
    window.onkeydown = this.startRecordingKeyEvent;
    window.onkeyup = this.stopRecordingKeyEvent;
    this.$nextTick(function () {
      if (text != null) {
        this.text = text;
        if (this.focusScriptId === 2) {
          this.$refs.scriptId.innerText = text;
        }
        localStorage.removeItem("editText");
      }
    });
    this.url = location.href;
  },

  methods: {
    initMedia() {
      let AudioContextConstruct =
        window.AudioContext || // Default
        window.webkitAudioContext || // Safari and old versions of Chrome
        false;

      if (AudioContextConstruct) {
        this.audioContext = new AudioContextConstruct();
        console.log(this.audioContext);
      } else {
        alert(
          "Уучлаарай, таны ашиглаж байгаа вэб ;хөтөч микрофон ашиглах боломжгүй хөтөч байна. Та вэб хөтчөө шинэчилнэ үү. " +
            "Эсвэл Google Chrome, Mozilla Firefox хөтчүүдийн аль нэгийг ашиглан ахин нэвтэрнэ үү."
        );
      }

      navigator.getUserMedia(
        { audio: true },
        this.onMediaSuccess,
        this.onMediaError
      );
    },
    onMngPaste(e) {
      e.preventDefault();

      if (e.clipboardData) {
        let content = (e.originalEvent || e).clipboardData.getData(
          "text/plain"
        );
        document.execCommand("insertText", false, content);
      } else if (window.clipboardData) {
        let content = window.clipboardData.getData("Text");
        document.selection.createRange().pasteHTML(content);
      }
    },
    startRecordingKeyEvent(event) {
      if (event.altKey && event.keyCode == 65) {
        event.preventDefault();
        if (event.repeat) {
          return;
        }
        console.log("key start");
        if (!this.audioContext) {
          this.initMedia();
          return;
        }
        this.startRecording();
      }
    },
    stopRecordingKeyEvent() {
      if (this.isRecording) {
        console.log("key stop");
        this.stopRecording();
      }
    },
    onMediaSuccess(stream) {
      this.mediaRecorder = new MediaRecorder(stream, {
        audioBitsPerSecond: 128000,
        mimeType: "audio/webm",
      });
      this.mediaRecorder.addEventListener(
        "dataavailable",
        this.saveChunkToRecording
      );
      this.mediaRecorder.addEventListener("stop", this.sendAudio);
    },
    startTimer(callback = () => {}) {
      this.timerValue = 90;
      this.timer = setInterval(() => {
        this.timerValue -= 10;
        if (this.timerValue <= 0) {
          this.timerValue = 0;
          this.stopTimer();
          callback();
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    saveChunkToRecording(event) {
      this.chunks.push(event.data);
    },
    sendAudio() {
      this.stopTimer();
      this.transcribeLoading = true;
      this.overlayed = true;
      this.overlayMsg = "Хөрвүүлж байна...";
      let blob = new Blob(this.chunks, { type: "audio/webm;codecs=pcm" });
      this.chunks = [];
      var headers = { token: this.getToken, "Content-Type": "audio/webm" };
      axios
        .post(`${process.env.VUE_APP_BASE_URL}/transcribe`, blob, { headers })
        .then((res) => {
          if (res.status == 200) {
            this.text = res.data;
            if (this.text.length > 0) {
              this.translate();
            }
          }
        })
        .catch((reason) => {
          this.errorFunc(reason);
        })
        .finally(() => {
          this.transcribeLoading = false;
          this.overlayed = false;
        });
    },
    onMediaError(e) {
      console.error("media error", e);
    },
    startRecording() {
      if (this.isRecording || this.mediaRecorder == null) {
        return;
      }
      console.log("Starting recorder");
      this.mediaRecorder.start();
      this.isRecording = true;
      this.overlayed = true;
      this.overlayMsg = "Сонсож байна...";
      this.startTimer(() => {
        this.stopRecording();
      });
    },
    stopRecording() {
      if (!this.isRecording || this.mediaRecorder == null) {
        return;
      }
      console.log("Stopping recorder");
      this.mediaRecorder.stop();
      this.isRecording = false;
    },
    changeInput(e) {
      this.text = e.target.innerText;
    },
    switchScripts() {
      const tempScript = this.scripts[0];
      this.scripts[0] = this.scripts[1];
      this.scripts[1] = tempScript;
      this.focusScriptId = this.focusScriptId == 1 ? 2 : 1;
      this.$cookie.set("focusScriptId", this.focusScriptId);
      this.renderCount++;
      this.translateText = "";
      this.text = "";
    },
    setactiveFontId(fontFaceId) {
      if (fontFaceId == this.activeFontId) {
        this.$toast.warning("Фонт сонгогдсон байна");
      } else {
        this.activeFontId = fontFaceId;
      }
    },
    translate() {
      if (this.text.length == "") {
        this.$toast.warning("Хөрвүүлэх бичвэрээ оруулна");
      } else {
        this.translateText = "";
        if (this.text.length <= this.limitTextCount) {
          this.translateRequest();
        } else {
          this.$toast.warning("Хөрвүүлэх бичвэрийн урт хэтэрсэн байна");
        }
      }
    },
    translateRequest() {
      this.convertLoading = true;
      var headers = { token: this.getToken };
      axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/translate/${
            this.focusScriptId == 1 ? "toMb" : "toCyrillic"
          }`,
          {
            body: this.text.trim(),
          },
          { headers }
        )
        .then((res) => {
          if (res.status == 200) {
            this.translateId = res.data.ID;
            this.translateText = res.data.ResultWord;
          }
          this.convertLoading = false;
        })
        .catch((reason) => {
          this.convertLoading = false;
          this.errorFunc(reason);
        });
    },
    sendReport() {
      this.reportLoading = false;
      var headers = { token: this.getToken };
      axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/report/${this.translateId}`,
          this.reportText,

          { headers }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$toast.success("Таны саналыг хүлээн авлаа, баярлалаа.");
          }
        })
        .catch((reason) => {
          this.errorFunc(reason);
        })
        .finally(() => {
          this.reportLoading = false;
          this.reportMenu = false;
        });
    },
    errorFunc(reason) {
      if (reason.response.status == 403 || reason.response.status == 401) {
        localStorage.setItem("editText", this.text);
        this.$cookie.set("focusScriptId", this.focusScriptId);
        window.location.href = process.env.VUE_APP_BASE_URL + "/login";
        this.$toast.error("Хэрэглэгчийн эрхээрээ дахин нэвтэрнэ үү!");
      } else {
        this.$toast.error(
          "Системийн ачаалал өндөр байгаа тул та түр хүлээгээд дахин оролдоно уу!"
        );
      }
    },
    copyText() {
      this.$clipboard(this.translateText);
      this.$toast.success("Бичвэрийг хуулж авлаа");
    },
    downloadText() {
      var canvas = document.getElementById("mnglCanvas");
      var link = document.createElement("a");
      link.download = "download.png";
      link.href = canvas.toDataURL();
      link.click();
      this.$toast.success("Бичвэрийг зураг хэлбэрээр татаж авлаа");
    },
    showCanvas() {
      var txt = this.translateText;
      var rowText = txt.split("\n");
      for (var i = 0; i < rowText.length; i++) {
        var word = rowText[i].split(" ");
        for (var n = 0; n < word.length; n++) {
          if (this.emoji.test(word[n])) {
            // emojiArray.push(word[n]);
            this.hasEmoji = true;
            // console.log("emoji check", this.emoji.test(word[n]));
          }
        }
      }
      this.canvasText = this.translateText;
      this.showCanvasDialog = true;
      this.$nextTick(() => {
        // console.log(this.$refs);
        // console.log(this.$refs.canvasRef);
        this.$refs.canvasRef.drawCanvas();
      });
    },
    updateFont(id) {
      this.activeFontId = id;
    },
    mnglColorChanger(textColor) {
      this.canvasTextColor = textColor;
      // console.log("color check", textColor);
    },
    ChangeCanvasTextFont() {
      this.canvasFont = parseInt(this.selectedFontSize);
      // console.log("c sd", this.canvasFont, typeof this.canvasFont);
    },
    CanvasBgColorChange(bgColor) {
      this.canvasBgColor = bgColor;
      // console.log("canvas color check", bgColor);
    },
    XMarginChange() {
      this.marginX = parseInt(this.xMargin);
      // console.log("check", this.marginX);
    },
    YMarginChange() {
      this.marginY = parseInt(this.yMargin);
      // console.log("check", this.marginY);
    },
    clearCanvas() {
      this.canvasTextColor = "#000000";
      this.textColor = "#000000";
      this.bgColor = "#ffffff";
      this.canvasBgColor = "#ffffff";
      this.isAlign = false;
      this.marginX = 140;
      this.marginY = 140;
      this.xMargin = 140;
      this.yMargin = 140;
      this.canvasFont = "44";
      this.selectedFontSize = "44";
      this.showCanvasDialog = false;
    },
    async postCanvasToFacebook() {
      var canvasElement = document.getElementById("mnglCanvas");
      var dataURL = canvasElement.toDataURL();
      var blob = await (await fetch(dataURL)).blob();
      console.log("blob checking", blob);
      var fileArray = [
        new File([blob], "download.png", {
          type: blob.type,
          lastModified: new Date().getTime(),
        }),
      ];
      var shareData = {
        files: fileArray,
      };
      // navigator.share(shareData);
      window.open(
        "https://www.facebook.com/sharer.php?u=https://kimo.mngl.net/img/kimo_solid.png",
        "facebook-popup",
        "height=500,width=600"
      );
      console.log("CHECK", shareData);
      // var canvas = document.getElementById("mnglCanvas");
    },
  },
};
</script>
<style>
.reportTextarea fieldset {
  border-width: 0.5px !important;
  border-style: solid !important;
  border-color: #7c7c7c !important;
  border-radius: 8px !important;
}
.reportTextarea .v-input__slot {
  padding: 15px;
}
</style>
<style scoped>
.customBtnColorless {
  font-weight: 600 !important;
  font-size: 14px !important;
  padding: 8px 12px !important;
  text-transform: none !important;
  border-radius: 12px !important;
  box-shadow: none !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.reportBtn {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: none;
  display: inline-flex;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  border: 0.5px solid #ff9a9a;
  border-radius: 8px;
  padding: 4px 8px;
  color: #ff9a9a;
  height: 32px;
  transition: all 200ms ease;
}
.reportBtn:hover {
  background: rgba(255, 82, 82, 0.1);
  color: #ff5252;
}
.reportBtn.mobile {
  display: none;
}

.converterFullContaner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(70vh - 17px);
  margin-top: -100px;

  /* background-color: red; */
}

.mobileMongolianFontContainer {
  display: none;
  width: 100%;
}
.converterContainer {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 30px rgba(53, 58, 54, 0.05);
  border: 0.5px solid #e5e5e5;
  border-radius: 8px;
  padding: 0 16px;
  background-color: #ffffff;
  height: 75%;
}

.helperContainer {
  display: flex;
  padding-top: 16px;
}

.questionContainer {
  width: 50%;
}

.fontDownloadContainer {
  width: 50%;
  font-size: 14px;
  color: #656565;
}

.downloadLink {
  color: #4fd774;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}
.converterHeader {
  display: flex;
  position: relative;
  background-color: #ffffff;
  border-bottom: 0.7px solid #e5e5e5;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.converterLangTitle {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #313131;
  padding: 16px 0;
  width: 50%;
}
.canvasDialogTitle {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #313131;
  padding: 16px 0;
  margin-left: 0px 4px;
}
.inputContainer {
  display: flex;
  border-bottom: 0.5px solid #e5e5e5;
  height: calc(100% - 116px);
}

.converterLeftContainer {
  width: 50%;
  border-right: 0.7px solid #e5e5e5;
  padding: 16px 16px 32px 16px;
  background-color: #ffffff;
  position: relative;
  height: 100%;
}

.textLimitContainer {
  font-family: "Roboto";
  position: absolute;
  right: 16px;
  bottom: 8px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: #c4c4c4;
}

.mobileTranslateBtnContainer {
  display: none;
  position: absolute;
  right: 16px;
  bottom: 6px;
}

.converterRightContainer {
  position: relative;
  width: 50%;
  padding: 16px 16px 32px 16px;
  background-color: #f5f5f5;
  height: 100%;
}

.converterFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  position: relative;
}
.chooseTitle {
  font-size: 18px;
  line-height: 21px;
  color: #313131;
  margin-right: 36px;
}

.switchButtonContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.iconButton {
  width: 36px;
  height: 36px;
}
.switchButton {
  text-align: center;
  width: 44px !important;
  height: 44px !important;
  background-color: #ffffff !important;
}

.iconButtonContainer {
  position: absolute;
  bottom: 4px;
  left: 0;
  padding: 0 8px;
  width: 100%;
  display: flex;
}

.convertLoading {
  position: absolute;
  left: 16px;
  top: 16px;
  cursor: pointer;
}
.invalidLength {
  color: #ff3333;
}

.textareaContainer {
  width: 100%;
  height: 100%;
  resize: none;
  outline: none;
  font-size: 16px;
  color: #313131;
  padding-right: 8px;
  text-align: justify;
}

.mongolianScriptContainer {
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 8px;
  font-weight: 400;
  outline: none;
  line-height: 2;
  white-space: pre-line;
}

.mongolianScriptContainerCanvas {
  height: 100%;
  width: 100%;
}
.message {
  margin-top: 50px;
}

.mongolianScriptFontStyle {
  font-family: "MongolianScript";
}

.dashitsedenFontStyle {
  font-family: "Classical Mongolian Dashtseden";
}

.mongolianCyrillicContainer {
  height: 100%;
  width: 100%;
  text-align: justify;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px;
  white-space: pre-line;
}

.mongolianScriptContainer::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: #f5f5f5;
}

.mongolianScriptContainer::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
}

.translateButtonContainer {
  position: absolute;
  top: 11px;
  left: 50%;
  transform: translate(-50%, 0);
}

.mongolianScriptContainer::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #c1c1c1;
}

.textareaContainer::-webkit-scrollbar-track,
.mongolianCyrillicContainer::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: #ffffff;
}

.textareaContainer::-webkit-scrollbar,
.mongolianCyrillicContainer::-webkit-scrollbar {
  width: 8px;
  background-color: #ffffff;
}

.textareaContainer::-webkit-scrollbar-thumb,
.mongolianCyrillicContainer::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #c1c1c1;
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  color: #ababab;
  font-weight: 400 !important;
  /* font-family: "Raleway";
  writing-mode: horizontal-tb;
  text-orientation: sideways-right;
  -webkit-font-feature-settings: "ccmp";
  -webkit-writing-mode: horizontal-tb;
  -ms-writing-mode: tb-lr;
  -o-writing-mode: horizontal-tb; */
}

.fontDropdownContainer {
  position: absolute;
  top: 12px;
}
.fontDropdownContainer.left {
  left: 0;
}
.fontDropdownContainer.right {
  right: 0;
}

.moreDesc {
  color: #2f2f30;
  font-size: 14px;
  text-align: justify;
  padding-right: 12px;
}

.infoIconBtn {
  width: 36px !important;
  height: 36px !important;
}

.infoContainer {
  display: flex;
  width: 350px;
  flex-direction: column;
  align-items: center;
  padding: 30px 24px 24px 30px;
  background-color: #ffffff;
}

/* .likeButton {
  display: flex;
  background-color: #ffffff !important;
  box-shadow: none;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  height: 40px !important;
  border-radius: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #747474;
  text-transform: none;
  width: 210px;
  justify-content: space-between;
  padding: 0 26px !important;
  margin: 24px 0 12px 0;
} */

.spellCheckButton {
  display: flex;
  background-color: #ffffff !important;
  box-shadow: none;
  box-sizing: border-box;
  height: 40px !important;
  border-radius: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #2f74e0;
  text-transform: none;
  width: 210px;
  justify-content: space-between;
  padding: 0 26px !important;
}

.infoTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* text-align: center; */
  letter-spacing: -0.01em;
  color: #3d4043;
  word-break: break-word;
}

.infoDesc {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #7c7c7c;
  margin-top: 12px;
}

.v-menu__content {
  position: absolute;
  display: inline-block;
  max-width: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 0;
  filter: drop-shadow(0px 30px 60px rgba(0, 0, 0, 0.2));
}

.focusWord {
  display: inline-flex;
  text-align: center;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid #000000;
  padding: 4px 0 10px 0;
  cursor: pointer;
  transition: all 0.3s;
  color: #000000;
}

.focusCyrillicWord {
  display: inline-flex;
  text-align: center;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid #000000;
  padding: 4px;
  cursor: pointer;
  transition: all 0.3s;
  color: #000000;
}

.focusWord:hover,
.focusCyrillicWord:hover {
  background-color: #d9dff5;
  transition: all 0.3s;
}

.likeContainer {
  display: flex;
  justify-content: space-between;
  width: 144px;
  margin: 12px 0 12px 0;
}

.likeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 1px solid #e5e5e5;
  border-radius: 28px;
  transition: ease-out 0.3s;
}

.likeButton:hover {
  background: #2f74e0;
  border: 1px solid #2f74e0;
}

.likeIcon {
  display: inline-block;
  width: 21px;
  height: 18px;
  background: black;
  mask-size: cover;
  mask-repeat: no-repeat;
  mask: url("/like.svg");
  background: #747474;
}

.likeButton:hover .likeIcon {
  background: #ffffff;
  transition: ease-out 0.3s;
}

.dislikeButton {
  width: 50px;
  height: 50px;
  border: 1px solid #e5e5e5;
  border-radius: 28px;
  transition: ease-out 0.3s;
}

.dislikeButton:hover {
  background: #ef476f;
  border: 1px solid #ef476f;
}

.dislikeIcon {
  display: inline-block;
  width: 21px;
  height: 18px;
  background: black;
  mask-size: cover;
  mask-repeat: no-repeat;
  mask: url("/dislike.svg");
  background: #747474;
}

.dislikeButton:hover .dislikeIcon {
  background: #ffffff;
  transition: ease-out 0.3s;
}
@media screen and (max-width: 1260px) {
  .fontDropdownContainer {
    display: flex;
    position: absolute;
    top: 15px;
    right: 0;
  }
  .fontDropdownContainer.left {
    right: 0;
    left: unset;
  }
  .fontDropdownContainer.right {
    left: unset;
    right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .moreDesc {
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  .reportBtn {
    display: none;
  }
  .reportBtn.mobile {
    display: inline-flex;
  }
  .fontDropdownContainer {
    display: flex;
    position: absolute;
    top: 10px;
    right: 0;
  }
  .converterFullContaner {
    height: auto;
    background-color: #f3f5f7;
    padding-bottom: 16px;
    margin-top: 0;
  }
  .converterContainer {
    background-color: #f3f5f7;
    padding: 0px;
    margin-top: 24px;
    border: none;
  }

  .inputContainer {
    flex-direction: column;
    border-bottom: none;
  }
  .chooseTitle {
    font-size: 14px;
  }

  .converterLangTitle {
    font-size: 12px;
    padding: 15px 0;
  }

  .switchButton {
    width: 30px !important;
    height: 30px !important;
  }

  .converterFooter {
    display: none;
  }
  .converterLeftContainer {
    width: 100%;
    border-right: none;
    height: 270px;
    padding-bottom: 50px;
  }

  .converterRightContainer {
    width: 100%;
    height: 250px;
    border-top: 0.5px solid #e5e5e5;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .textareaContainer {
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 16px;
  }

  .convertLoading {
    font-size: 14px;
  }

  .mongolianScriptContainer,
  .mongolianCyrillicContainer,
  .textLimitContainer {
    font-size: 14px;
  }

  .mobileMongolianFontContainer {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  .textLimitContainer {
    left: 16px;
    bottom: 8px;
  }

  .mobileTranslateBtnContainer {
    display: block;
  }

  .helperContainer {
    display: none;
  }

  .infoContainer {
    display: flex;
    width: 240px;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
  }

  .infoTitle {
    font-size: 14px;
    line-height: 20px;
  }

  .infoDesc {
    font-size: 10px;
    line-height: 14px;
  }
}
.alignCenter {
  height: 38px;
}
</style>
