<template>
  <v-app id="inspire">
    <v-app-bar app dense elevation="0">
      <Header />
    </v-app-bar>
    <div class="main">
      <router-view default="{path: '/'}"></router-view>
    </div>
    <div class="footer">
      <Footer />
    </div>
  </v-app>
</template>
<script>
import Header from "./components/Header";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Default Title",
    // all titles will be injected into this template
    titleTemplate: "%s | My Awesome Webapp",
  },
  data() {
    return {
      sidebar: false,
    };
  },
};
</script>

<style>

@font-face {
  font-family: "Raleway";
  src: url(../public/fonts/Raleway-VariableFont_wght.ttf),
    url(../public/fonts/Raleway-Italic-VariableFont_wght.ttf);
}

* {
  font-family: Raleway;
}
#app {
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.main {
  display: flex;
  min-height: calc(100vh - 70px);
  padding-top: 70px;
  background-color: #ffffff;
}

.loginButton {
  background-color: #787ff7 !important;
  color: white !important;
  font-size: 12px !important;
  outline: none !important;
}

.v-footer {
  padding: 0 !important;
}

.v-toolbar__content {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  flex-wrap: wrap;
  height: 70px !important;
  /* border: 1px solid #e5e5e5; */
  padding: 0 !important;
  box-shadow: 0px 3px 6px rgba(74, 83, 96, 0.06);
}

/* @media screen and (max-width: 600px) {
  .v-toolbar__content {
    background-color: #f3f5f7;
  }
} */

.v-btn {
  letter-spacing: 0 !important;
}

.footer {
  width: 100%;
  background-color: #ffffff;
}
</style>
