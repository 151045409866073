<template>
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.896447 3.47482C0.701184 3.67008 0.701184 3.98666 0.896447 4.18192L4.07843 7.3639C4.27369 7.55917 4.59027 7.55917 4.78553 7.3639C4.9808 7.16864 4.9808 6.85206 4.78553 6.6568L1.95711 3.82837L4.78553 0.999942C4.9808 0.80468 4.9808 0.488097 4.78553 0.292835C4.59027 0.097573 4.27369 0.097573 4.07843 0.292835L0.896447 3.47482ZM1.25 4.32837H17.25V3.32837H1.25V4.32837Z"
      fill="#909090"
    />
    <path
      d="M17.6036 11.5252C17.7988 11.3299 17.7988 11.0133 17.6036 10.8181L14.4216 7.6361C14.2263 7.44083 13.9097 7.44083 13.7145 7.6361C13.5192 7.83136 13.5192 8.14794 13.7145 8.3432L16.5429 11.1716L13.7145 14.0001C13.5192 14.1953 13.5192 14.5119 13.7145 14.7072C13.9097 14.9024 14.2263 14.9024 14.4216 14.7072L17.6036 11.5252ZM1.25 11.6716H17.25V10.6716H1.25V11.6716Z"
      fill="#909090"
    />
  </svg>
</template>
<script>
export default {
  name: "SwitchIcon",
};
</script>
