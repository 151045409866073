<template>
  <div class="pageFullContainer">
    <kimo-title />
    <div class="customContainer">
      <MobileConverter/>
<!--      <Converter class="desktopConverter" />-->
<!--      <DesktopConverter class="desktopConverter"/>-->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MobileConverter from "../components/MobileConverter.vue";
// import DesktopConverter from "../components/DesktopConverter.vue";
import KimoTitle from "../components/KimoTitle.vue";
// import Converter from "@/components/Converter";

export default {
  components: {
    // Converter,
    MobileConverter,
    // DesktopConverter,
    KimoTitle,
  },
  data() {
    return {
      bichig: "",
      cyrillic: "",

      items: [
        {
          value: 1,
          text: "Монгол Кирилл",
        },
        {
          value: 2,
          text: "Монгол Бичиг",
        },
      ],
      value1: 1,
      value2: 2,
      rules: [(v) => (v || "").length <= 200 || "Max 200 characters"],
    };
  },
  computed: {
    getToken() {
      return this.$cookie.get("token");
    },
  },
  methods: {
    handleInput: function(e) {
      this.bichig = e.target.innerText;
    },

    assignNewValue(oldValue, anotherOne) {
      if (oldValue == 0) {
        return anotherOne == 1 ? 2 : 1;
      } else return oldValue;
    },
    swapLanguage() {
      this.value1 = this.value2;
      this.bichig = "";
      this.cyrillic = "";
      document.getElementsByClassName("mongolBichig")[0].innerHTML = "";
      document.getElementById("textarea").value = "";
    },
    translate() {
      var headers = { token: this.getToken };
      if (this.value1 === 1) {
        axios
          .post(
            `${process.env.VUE_APP_BASE_URL}/translate/toMb`,
            {
              body: this.cyrillic,
            },
            { headers }
          )
          .then((res) => {
            document.getElementsByClassName("mongolBichig")[0].innerHTML =
              res.data.bichig;
          })
          .catch((reason) => {
            if (reason.response.status == 403) {
              window.location.href = process.env.VUE_APP_BASE_URL + "/login";
            }
          });
      } else {
        axios
          .post(
            `${process.env.VUE_APP_BASE_URL}/translate/toCyrillic`,
            {
              body: this.bichig,
            },
            { headers }
          )
          .then((res) => {
            document.getElementById("textarea").value = res.data.bichig;
          })
          .catch((reason) => {
            if (reason.response.status == 403) {
              // console.log(process.env.VUE_APP_BASE_URL);
              window.location.href = process.env.VUE_APP_BASE_URL + "/login";
            }
          });
      }
    },
    translate2Cyrillic() {
      axios
        .post(`${process.env.VUE_APP_BASE_URL}/bichig2cyrillic`, {
          body: this.data,
        })
        .then((res) => {
          document.getElementsByClassName("mongolBichig")[0].innerHTML =
            res.data.bichig;
        });
    },
  },
  watch: {
    value1: function(newValue, oldValue) {
      if (this.value1 !== null) {
        this.value2 = this.assignNewValue(this.value1, this.value2);
      }
      if (newValue === this.value2)
        this.value2 = this.assignNewValue(oldValue, this.value2);
    },
    value2: function(newValue, oldValue) {
      if (newValue === this.value1)
        this.value1 = this.assignNewValue(oldValue, this.value1);
    },
  },
};
</script>

<style scope>
.pageFullContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
}

.mobileConverter {
  display: none !important;
}

.desktopConverter {
  display: flex !important;
}

@media screen and (max-width: 768px) {
  .pageFullContainer {
    background-color: #f3f5f7;
    flex-direction: column-reverse;
  }

  .mobileConverter {
    display: flex !important;
  }

  .desktopConverter {
    display: none !important;
  }
}
</style>
