<template>
  <div class="dropdownContainer">
    <v-btn
      class="activeDropButton"
      :class="dropButtonClick ? 'customBtn' : ''"
      @click="dropButtonClick = !dropButtonClick"
    >
      <div class="dropdownBtnContainer">
        <span>{{ title }}</span>
        <triangle-icon :width="12" :height="8" />
      </div>
    </v-btn>
    <v-btn
      icon
      class="mobileActiveButton"
      @click="dropButtonClick = !dropButtonClick"
      ><down-arrow
    /></v-btn>

    <div v-if="dropButtonClick" class="fontsContainer">
      <v-btn
        v-for="(font, index) in fonts"
        :key="index"
        :class="activeFontId == font.id ? 'fontActiveButton' : 'fontButton'"
        @click="changeFont(font)"
      >
        <div class="dropdownBtnContainer">
          <span> {{ font.title }} </span>
          <check-icon v-if="activeFontId == font.id" />
        </div>
      </v-btn>
    </div>
  </div>
</template>
<script>
import TriangleIcon from "../components/icons/TriangleIcon.vue";
import CheckIcon from "../components/icons/CheckIcon.vue";
import DownArrow from "../components/icons/DownArrow.vue";
export default {
  components: {
    CheckIcon,
    TriangleIcon,
    DownArrow,
  },
  props: {
    activeFontId: Number,
  },
  data() {
    return {
      dropButtonClick: false,
      title: "Монгол бичгийн фонт сонгох",
      fonts: [
        { id: 0, title: "Mongolian script" },
        { id: 1, title: "Classical Mongolian Dashtseden" },
      ],
    };
  },
  mounted() {
    const id = this.$cookie.get("activeFontId");
    if (id != null) {
      this.title = this.fonts[id].title;
    }
  },
  methods: {
    changeFont(font) {
      this.title = font.title;
      this.dropButtonClick = false;
      this.$cookie.set("activeFontId", font.id);
      this.$emit("updateFont", font.id);
    },
  },
};
</script>
<style scoped>
.dropdownContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.fontsContainer {
  display: flex;
  flex-direction: column;
  background-color: #ffffff !important;
  border: 1px solid #33a953 !important;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  z-index: 99;
  width: 230px;
}

.mobileActiveButton {
  display: none;
}

.dropdownBtnContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activeDropButton {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  border: 1px solid #33a953 !important;
  border-radius: 16px !important;
  background-color: #ffffff !important;
  color: #33a953 !important;
  padding: 8px 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  font-size: 12px !important;
  transition: 0.3s !important;
  text-transform: none !important;
  box-shadow: none !important;
  width: 230px;
}

.customBtn {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom: none !important;
}

.fontActiveButton {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  border-bottom: 1px solid #33a953 !important;
  background-color: #eaf7ed !important;
  color: #33a953 !important;
  padding: 8px 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  font-size: 12px !important;
  transition: 0.3s !important;
  text-transform: none !important;
  border-radius: 0px;
  box-shadow: none;
}

.fontActiveButton:last-child {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  border-bottom: none !important;
}

.fontButton {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  border-bottom: 1px solid #33a953 !important;
  background-color: #ffffff !important;
  color: #33a953 !important;
  padding: 8px 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  font-size: 12px !important;
  transition: 0.3s !important;
  text-transform: none !important;
  border-radius: 0px;
  box-shadow: none;
}

.fontButton:last-child {
  border-bottom: none !important;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

@media screen and (max-width: 1260px) {
  .activeDropButton {
    display: none;
  }

  .mobileActiveButton {
    display: flex;
    width: 30px !important;
    height: 30px !important;
    margin-right: 4px;
  }

  .dropdownContainer {
    width: 30px;
  }

  .fontsContainer {
    margin-top: 4px;
  }
}
</style>
