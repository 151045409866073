<template>
  <v-container grid-list-xs>
    <v-list>
      <v-list-group sub-group no-action class="listGroup" v-for="(item,i) in question" :key="i">
        <template v-slot:activator>
          <v-list-item-content>
            <div class="title">{{item[0]}}</div>
          </v-list-item-content>
        </template>
        <v-list-item link>
          <v-icon style="color: #787ff7">mdi-information-outline</v-icon>
          <div class="answer">{{item[1]}}</div>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-container>
</template>
<script>
  export default {
    name: "Help",
    data: () => ({
      question: [
        [
          "КИМО гэж юу вэ?",
          "Хиймэл оюуны тусламжтай кирил бичгээс монгол бичигт мөн монгол бичгээс кирил бичиг хөрвүүлдэг хөрвүүлэгч юм."
        ],
        [
          "Хэрхэн ашиглах вэ?",
          "Хөрвүүлэх хослолоо сонгоод бичвэрээ оруулж, хөрвүүлэх товчийг дарна."
        ]
      ]
    })
  };
</script>
<style scoped>
  .listGroup {
    color: #2c3e50;
    font-weight: 400;
    line-height: 1.5;
    font-size: 18px;
    text-align: left;
    background-color: #f8f9fc;
    border-bottom: 2px solid #787ff7 !important;
  }

  .answer {
    text-align: justify;
    /* border-bottom: 2px solid #787ff7 !important; */
    padding: 20px;
    line-height: 1.5;
    font-size: 16px;
    font-weight: 400;
    color: #2c3e50;
  }

  .listGroup:last-of-type {
    border-bottom: none !important;
  }
</style>